var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex df-col",
    class: {
      'full-dim-height': _vm.getFullDimShowState
    }
  }, [_c('div', {
    staticClass: "DAV-workshop__trunkFull"
  }, [_vm.isLoading ? _c('spinner') : _vm._e(), _c('header', {
    staticClass: "DAV-topBar"
  }, [_c('div', {
    staticClass: "DAV-topBar__select"
  }, [_c('Dropdown', {
    attrs: {
      "value": _vm.page.value,
      "options": _vm.page.options,
      "type": "small"
    },
    on: {
      "updateTarget": function updateTarget(v) {
        return _vm.setPage(v);
      }
    }
  })], 1), _c('div', {
    staticClass: "DAV-topBar__device"
  }, [_c('button', {
    staticClass: "DAV-topBar__device-pc",
    class: {
      'DAV-topBar__device--active': _vm.deviceType === 'desktop'
    },
    on: {
      "click": function click($event) {
        return _vm.setDeviceType('desktop');
      }
    }
  }, [_vm._v(" pc ")]), _c('button', {
    staticClass: "DAV-topBar__device-mobile",
    class: {
      'DAV-topBar__device--active': _vm.deviceType === 'mobile'
    },
    on: {
      "click": function click($event) {
        return _vm.setDeviceType('mobile');
      }
    }
  }, [_vm._v(" mobile ")])]), _c('div', {
    staticClass: "DAV-topBar__theme-select",
    on: {
      "click": _vm.selectedTheme
    }
  }, [_c('p', [_vm._v("테마 선택하기")])]), _c('button', {
    staticClass: "DAV-topBar__prev",
    on: {
      "click": _vm.closePopup
    }
  }, [_vm._v(" 테마 미리보기 닫기 ")])]), _c('div', {
    staticClass: "DAV-preview"
  }, [_c('div', {
    staticClass: "DAV-preview__shop",
    class: _vm.setPreviewClass
  }, [_c('iframe', {
    staticStyle: {
      "width": "100%",
      "height": "100%",
      "border": "0"
    },
    attrs: {
      "id": "introduction-preview",
      "src": "/preview/".concat(this.$route.query.id),
      "scrolling": "no"
    }
  })])])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }