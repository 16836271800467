<template>
  <div class="d-flex df-col" :class="{'full-dim-height': getFullDimShowState}">
    <div class="DAV-workshop__trunkFull">
      <spinner v-if="isLoading" />
      <header class="DAV-topBar">
        <div class="DAV-topBar__select">
          <Dropdown
            :value="page.value"
            :options="page.options"
            type="small"
            @updateTarget="v => setPage(v)"
          />
        </div>
        <div class="DAV-topBar__device">
          <button
            class="DAV-topBar__device-pc"
            :class="{'DAV-topBar__device--active': deviceType === 'desktop'}"
            @click="setDeviceType('desktop')"
          >
            pc
          </button>
          <button
            class="DAV-topBar__device-mobile"
            :class="{'DAV-topBar__device--active': deviceType === 'mobile'}"
            @click="setDeviceType('mobile')"
          >
            mobile
          </button>
        </div>
        <div class="DAV-topBar__theme-select" @click="selectedTheme">
          <p>테마 선택하기</p>
        </div>
        <button class="DAV-topBar__prev" @click="closePopup">
          테마 미리보기 닫기
        </button>
      </header>
      <div class="DAV-preview">
        <div class="DAV-preview__shop" :class="setPreviewClass">
          <iframe
            id="introduction-preview"
            :src="`/preview/${this.$route.query.id}`"
            style="width: 100%; height: 100%; border: 0"
            scrolling="no"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleTheme = createNamespacedHelpers("ModuleTheme");
const ModuleAuth = createNamespacedHelpers("ModuleAuth");
const ModuleIntroCommon = createNamespacedHelpers("ModuleIntroCommon");

export default {
  data() {
    return {
      page: {
        options: [],
        value: 0
      },
      deviceType: null,
      isLoading: false,
      width: 0
    };
  },
  computed: {
    // class binding - 디바이스 타입
    setPreviewClass() {
      return {
        "DAV-preview__shop--mobile": this.deviceType === "mobile",
        "DAV-preview__shop--desktop": this.deviceType === "desktop"
      };
    },
    ...ModuleAuth.mapGetters(["getLoginStatus"]),
    ...ModuleTheme.mapGetters(["getThemeLoadData"]),
    ...ModuleIntroCommon.mapGetters(["getFullDimShowState"])
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.width = window.innerWidth;

    // 디바이스 선택
    this.deviceType =
      document.documentElement.clientWidth < 1024 ? "mobile" : "desktop";

    // 테마 불러오기
    this.themeLoad();

    // 테마 선택 팝업 관련 height
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--height", `${vh}px`);
    window.addEventListener("resize", this.resizeHeight);

    //로그인 성공 후, 테마 선택으로 진입 시
    if (this.$route.query.theme && this.getLoginStatus) {
      this.setFullDimData(Number(this.$route.query.theme));
      this.setFullDimShowState(true);
    }
  },
  beforeDestroy() {
    document.documentElement.style.setProperty("--height", ``);
    window.removeEventListener("resize", this.resizeHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.width = window.innerWidth;
    },
    // 테마 api 호출
    themeLoad() {
      let payload = {};
      payload.mode = "partner";
      payload.themeId = this.$route.query.id;

      this.isLoading = true;
      this.actThemeLoad(payload).then(() => {
        const theme = this.getThemeLoadData;
        // page options set
        theme.editablePages.map((i, index) => {
          this.page.options.push({text: i.name, value: index});
        });

        // data send to iframe
        setTimeout(() => {
          document
            .getElementById("introduction-preview")
            .contentWindow.postMessage(
              {
                action: "init",
                id: "introduction-preview",
                theme,
                device: this.deviceType
              },
              `${window.location.origin}/preview`
            );
        }, 1000);
        this.isLoading = false;
      });
    },
    // 페이지 선택
    setPage(index) {
      this.page.value = index;
      // page send to iframe
      document
        .getElementById("introduction-preview")
        .contentWindow.postMessage(
          {action: "page", page: index},
          `${window.location.origin}/preview`
        );
    },
    // 디바이스 선택
    setDeviceType(deviceType) {
      this.deviceType = deviceType;
      // device send to iframe
      document
        .getElementById("introduction-preview")
        .contentWindow.postMessage(
          {action: "device", device: this.deviceType},
          `${window.location.origin}/preview`
        );
    },
    // 테마 선택 팝업 관련 height
    resizeHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--height", `${vh}px`);
    },
    //테마 선택 레이어 팝업 노출(비로그인: 로그인 이동)
    selectedTheme() {
      const theme = this.$route.query.id;
      if (this.getLoginStatus) {
        this.setFullDimData(Number(theme));
        this.setFullDimShowState(true);
      } else {
        const url = document.URL;
        const splitUrl = url.split("?");
        const params = new URLSearchParams(splitUrl[1]);
        params.set("theme", theme.toString());
        const targetPage = splitUrl[0] + "?" + params.toString();

        this.$router.push(
          "/accounts/login?callback=" + encodeURIComponent(targetPage)
        );
      }
    },
    // 팝업 닫기
    closePopup() {
      window.close();
    },
    ...ModuleIntroCommon.mapMutations([
      "setFullDimData",
      "setFullDimShowState"
    ]),
    ...ModuleTheme.mapActions(["actThemeLoad"])
  },
  components: {
    Spinner: () => import("@/components/basis/spinner.vue")
  }
};
</script>

<style lang="scss" scoped>
.DAV-workshop__trunkFull {
  z-index: 3;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #e7e9ec;
}

.DAV-topBar__select::v-deep .DAV-dropdown button {
  height: 36px;
  font-size: 14px;
}

.DAV-topBar {
  border-bottom: none;

  &__select {
    position: absolute;
    top: 10px;
    left: 18px;
    width: 200px;
    height: 36px;
    font-size: 16px;
  }

  &__prev {
    position: absolute;
    top: 16px;
    right: 25px;
    width: 25px;
    height: 25px;
    background-image: url($path + "btn_close_popup.svg");
    background-repeat: no-repeat;
    background-position: center;
    text-indent: -9999px;
    background-size: cover;
  }

  &__theme-select {
    position: absolute;
    top: 10px;
    right: 80px;
    width: 96px;
    height: 36px;
    border-radius: 8px;
    background: #54c7a2;
    text-align: center;
    cursor: pointer;

    p {
      color: #fff;
      font-size: 12px;
      line-height: 36px;
    }
  }
}
.DAV-preview {
  position: fixed;
  top: 56px;
  right: 0px;
  bottom: 0;
  left: 0;
  background-color: #e7e9ec;

  &__shop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background-color: #ffffff;
  }
  &__shop--desktop {
    margin: 8px;
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .DAV-preview__shop--mobile {
    width: 421px;
    left: 50%;
    transform: translateX(-50%);
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .full-dim-height {
    height: calc(var(--height, 1vh) * 100);
  }

  .DAV-topBar__device {
    right: 64px;
  }

  .DAV-preview__shop {
    &--desktop {
      margin: 0;
    }
  }

  .DAV-topBar__theme-select {
    width: 100%;
    position: fixed;
    top: auto;
    bottom: 0px;
    left: 0px;
    z-index: 2;
    height: 56px;
    opacity: 0.9;
    border-radius: 0;

    p {
      font-size: 18px;
      line-height: 56px;
    }
  }
}
.mgb-56 {
  margin-bottom: 56px;
}
</style>
